.gm-style-iw-c {
    padding: 0 !important;
    padding-left: 10px;
}

button.gm-ui-hover-effect {
    visibility: hidden;
}

.pickup {
    position: absolute;
    top: 21px;
    right: 0px;
    font-size: 18px;
    font-weight: bold;
}

.info-window {
    margin: 3px;
}

.marker-label {
/*    position: relative;
    bottom: 20px;*/
    /*color: #fff;*/
    /*text-shadow: #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px;*/
}

.dispatch-order {
    color: orange;
}

#map
{
    position: fixed !important;
    height: 100% !important;
    width: 100% !important;
}
